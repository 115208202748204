<template>
    <div class="materialSupplyComparison" v-loading="loading">
        <table class="base-table">
            <tr>
                <th>供应商计划编号</th>
                <td>{{ info.no }}</td>
                <th>供应时间</th>
                <td style="padding-left: 0.15rem">
                    {{ info.purchase_date }}
                </td>
            </tr>
            <tr>
                <th>累计采购供应量</th>
                <td>{{ info.purchased_count }}</td>
                <th>累计成品供应量</th>
                <td>{{ info.finished_product_count }}</td>
            </tr>
            <tr>
                <th>损耗率</th>
                <td>{{ info.loss_rate }}</td>
                <th>固定损耗</th>
                <td>{{ info.fixed_loss_rate }}</td>
            </tr>
        </table>
        <div class="table-bg">
            <el-table
                :data="info.list"
                style="width: 100%"
                class="table-list"
                height="calc(100% - 1.4rem)"
            >
                <el-table-column
                    label="序号"
                    type="index"
                    width="70"
                >
                </el-table-column>
                <el-table-column
                    prop="child_type_name"
                    label="成品料"
                    class-name="notice-cell"
                >
                </el-table-column>
                <el-table-column
                    prop="spec_name"
                    label="规格"
                >
                </el-table-column>
                <el-table-column
                    prop="finished_product_count"
                    label="成品供应量"
                >
                </el-table-column>
                <el-table-column
                    prop="finished_product_ratio"
                    label="成品料占比"
                >
                </el-table-column>
                <el-table-column
                    prop="regulations_ratio"
                    label="规定比例(%)"
                >
                </el-table-column>
                <el-table-column
                    prop="allow_deviation"
                    label="允许偏差(%)"
                >
                </el-table-column>
                <el-table-column
                    prop="actual_deviation"
                    label="实际偏差(%)"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'material-supply-comparison',
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            statement_number: '',
            info: {
                // 供应计划编号
                no: '',
                // 供应时间
                purchase_date: '',
                // 累计采购量
                purchased_count: null,
                // 成品加工量
                finished_product_count: null,
                // 损耗率
                loss_rate: null,
                // 固定损耗率
                fixed_loss_rate: null,
                // 数据列表
                list: [],
            },
        };
    },
    created() {
        this.statement_number = this.extr.statement_number;
        this.getInfo();
    },
    methods: {
        getInfo() {
            this.loading = true;
            this.$axios.get(`/interfaceApi/procurement/material_account_statement/get_material_supply_comparison/${this.statement_number}`)
                .then(res => {
                    this.info = res;
                }).catch(e => {
                    this.$message.error(e.ErrorCode.Message);
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.materialSupplyComparison
    width 100%;
    height 100%;
    padding 0.2rem
    .base-table
        border-spacing 0px
        border-collapse collapse
        width 100%
        table-layout fixed
        margin 0.1rem 0
        tr
            th,td
                font-size 0.16rem
                border 1px solid #D7D7D7
                text-align left
                padding 0.04rem 0.2rem
                .cell-space
                    width 100%;
                    display flex
                    justify-content space-between
            th
                padding 0.08rem 0.2rem
                color #022782
                background #F6F8FC
                .el-button
                    color #fff;
    >>> .el-table
        border-collapse collapse
        th,td
            height 0.4rem
            padding 0
        th
            text-align center
            background #EDF0F5
            color #022782
            .cell
                font-size 0.14rem
        td
            color #333333
            font-size 0.14rem
        .hight-cell
            color #FF4D4F
            background: #FFF1F0;
</style>
